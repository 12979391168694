.mockup-phone {
  border: none;
  border-radius: 0;
  background: url('../../assets/iPhoneX.png') no-repeat top center;
  background-size: contain;
  padding: 95px 42px 25px;
  max-width: 365px;
  min-width: 365px;
  height: 640px;
  min-height: 640px;
  overflow: hidden;

  .camera {
    z-index: 51s;
    width: 120px;
    height: 20px;
    background-color: #444;
    border-radius: 0 0 20px 20px;
    margin: 0px auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
  }

  .message{
    background: $white;
    max-height: 530px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 20px;
    border-radius: 0 0 34px 34px;

    &-header{
      padding: 15px 15px 0;

      .logo{
        margin-right: 15px;
        position: relative;
        width: 80px;
        height: 60px;
        border: solid 1px $gray-300;
        border-radius: 3px;
        overflow: hidden;

        img{
          position: absolute;
          max-width: 90%;
          max-height: 60px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .message-sender{
        width: calc( 100% - 95px);

        > div:first-child{
          line-height: 1.2;
        }
      }

      .text-base{
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    &-content{
      padding: 0 15px 15px;
      font-size: 12px;
      line-height: 1.4;

      p{
        margin-bottom: 20px;

        br{
          display: none;
        }
      }
    }
  }
}

